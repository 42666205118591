import { Autocomplete } from '@mui/material';
import { TextField } from '@mui/material';
import React from 'react';
import { useCallback } from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { formControlActions } from 'store/formControl/formControlSlice';

// TODO: Add component prop-types. Disabling the eslint rule for now.
/* eslint-disable react/prop-types */
function InputSelect({
    fieldName,
    initialValue = null,
    value,
    label,
    touched,
    type,
    error,
    formId,
    required,
    lookup,
    disabled,
    blurHandler = () => {},
    changeHandler = () => {},
    inputValueChangeHandler = () => {},
    automation,
    validationField,
    referenceField,
    referenceValue,
    validationStyles,
    containerClasses,
    forceFieldRegistration,
    isOptionEqualToValueFn,
    helperText,
    classes,
    size = 'small',
    sx,
    loading,
    ...props
}) {
    const backgroundColor = validationStyles?.color ? { backgroundColor: validationStyles?.color } : null;

    const dispatch = useDispatch();
    useEffect(() => {
        if (!formId) return;
        dispatch(
            formControlActions.registerNewFormField({
                formId,
                fieldName,
                initialValue,
                disabled: disabled && !forceFieldRegistration,
                required,
            })
        );
        if (required && initialValue) {
            dispatch(
                formControlActions.setFieldError({
                    formId,
                    fieldName,
                    error: false,
                })
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, fieldName, formId, initialValue, required, forceFieldRegistration]);

    const getOptionLabel = useCallback(
        function (option) {
            if (lookup?.displayExpr) return option?.[lookup?.displayExpr];
        },
        [lookup?.displayExpr]
    );

    const hasError = touched && error; // && required;

    const acValue =
        lookup?.dataSource?.find((data) => {
            let activeOption = false;
            if (data?.[lookup?.displayExpr]) activeOption = data?.[lookup?.displayExpr] === value;
            if (data?.[lookup?.valueExpr]) activeOption = data?.[lookup?.valueExpr] === value;
            if (referenceField && referenceValue) {
                activeOption = data?.[lookup?.referenceExpr] === referenceValue;
            }

            return activeOption;
        }) ?? value;

    return (
        <Autocomplete
            containerclasses={containerClasses}
            classes={classes}
            autoSelect
            autoComplete
            getOptionLabel={lookup?.displayExpr && getOptionLabel}
            value={acValue || null}
            isOptionEqualToValue={isOptionEqualToValueFn}
            onInputChange={inputValueChangeHandler.bind({
                lookup,
                formId,
                fieldName,
                type,
                error,
                required,
                referenceField,
                referenceValue,
            })}
            onChange={changeHandler.bind({
                lookup,
                formId,
                fieldName,
                type,
                error,
                required,
                referenceField,
            })}
            onBlur={blurHandler.bind({
                required,
                touched,
                error,
                formId,
                fieldName,
                validationField,
            })}
            freeSolo={!lookup?.displayExpr || referenceField}
            options={lookup?.dataSource || []}
            disabled={disabled}
            renderInput={(params) => {
                return (
                    <TextField
                        fullWidth
                        {...params}
                        helperText={`${helperText && hasError ? helperText : ''} `}
                        label={label}
                        type={type}
                        error={hasError}
                        name={fieldName}
                        InputLabelProps={{ shrink: true }}
                        automation={automation}
                        size={size}
                        sx={sx}
                        {...backgroundColor}
                    />
                );
            }}
            loading={loading}
            {...props}
        />
    );
}

export default React.memo(InputSelect);
