import { Divider, Stack } from '@mui/material';
import React, { useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import RequestTicketItem from 'features/RequestTickets/Components/RequestTicketItem.jsx';
import RequestTicketDetailDialog from 'features/RequestTickets/Components/RequestTicketDetailDialog.jsx';

const RequestTicketList = (props) => {
    const { requestList } = props;
    const detailDialogRef = useRef();

    const requestTicketListRenderer = useMemo(() => {
        return requestList?.map((item) => (
            <RequestTicketItem
                key={item.id}
                ticketItem={item}
                onMessageButtonClick={async () => {
                    detailDialogRef.current?.showDialog(item);
                }}
            />
        ));
    }, [requestList]);

    return (
        <>
            <Stack divider={<Divider />}>{requestTicketListRenderer}</Stack>
            <RequestTicketDetailDialog ref={detailDialogRef} />
        </>
    );
};

export default RequestTicketList;
RequestTicketList.propTypes = {
    requestList: PropTypes.arrayOf(PropTypes.object),
};
