import { styled, Switch } from '@mui/material';

export const CustomSwitch = styled(Switch)({
    width: 46,
    height: 20,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '200ms',
        '&.Mui-checked': {
            transform: 'translateX(26px)',
            color: 'var(--color-white)',
            '& + .MuiSwitch-track': {
                backgroundColor: 'var(--color-green)',
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color: 'var(--color-white)',
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: 0.7,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 16,
        height: 16,
        backgroundColor: 'var(--color-white)',
    },
    '& .MuiSwitch-track': {
        borderRadius: 20,
        backgroundColor: 'var(--color-grey)',
        opacity: 1,
        transition: 500,
    },
});
