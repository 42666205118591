import { CustomSwitch } from './SwitchStyled';
import PropTypes from 'prop-types';

Switch.propTypes = {
    checked: PropTypes.bool,
    onChange: PropTypes.func,
};

export default function Switch({ checked, onChange }) {
    return <CustomSwitch checked={checked} onChange={onChange}></CustomSwitch>;
}
