import PageWithBreadcrumbs from 'components/pages/PageWithBreadcrumbs';
import RequestTicketsBreadcrumbsBar from 'features/RequestTickets/Components/BreadcrumbsBar.jsx';
import RequestTicketTable from 'features/RequestTickets/RequestTicketTable.jsx';
import RequestTicketFilterBar from 'features/RequestTickets/Components/RequestTicketFilterBar.jsx';
import PageWithLeftSideBar from 'components/pageWithLeftSideBar/PageWithLeftSideBar.jsx';
import { useRef, useState } from 'react';
import { REQUEST_TICKET_STATUS } from 'features/RequestTickets/utils/requestTicketConstants.js';

const DEFAULT_CHECKED_STATUS = {
    [REQUEST_TICKET_STATUS.OPEN]: true,
    [REQUEST_TICKET_STATUS.ON_HOLD]: true,
    [REQUEST_TICKET_STATUS.CLOSED]: true,
    [REQUEST_TICKET_STATUS.ESCALATED]: true,
};

const RequestTickets = () => {
    const requestTicketTableRef = useRef();
    const [searchText, setSearchText] = useState('');
    const [checkedStatus, setCheckedStatus] = useState(DEFAULT_CHECKED_STATUS);

    return (
        <PageWithBreadcrumbs breadcrumbsBar={<RequestTicketsBreadcrumbsBar requestTicketTableRef={requestTicketTableRef} />}>
            <PageWithLeftSideBar
                sideBar={
                    <RequestTicketFilterBar
                        onSearchChange={setSearchText}
                        onFilterChange={setCheckedStatus}
                        defaultCheckedStatus={DEFAULT_CHECKED_STATUS}
                    />
                }
            >
                <RequestTicketTable ref={requestTicketTableRef} checkedStatus={checkedStatus} searchText={searchText} />
            </PageWithLeftSideBar>
        </PageWithBreadcrumbs>
    );
};

export default RequestTickets;
