import Dialog from 'components/UI/dialogs/Dialog.jsx';
import { useDialog } from 'components/UI/dialogs/hooks/useDialog.js';
import { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react';
import { getRequestTicketDetailEmbeddedUrl } from 'services/requestTicketService.js';
import { useDataFetcher } from 'hooks/useDataFetcher.js';
import { IframeStyled } from 'features/RequestTickets/Components/RequestTicketDetailDialogStyled.js';
import { Stack } from '@mui/material';
import { LoadPanel } from 'devextreme-react/load-panel';

const RequestTicketDetailDialog = forwardRef((props, ref) => {
    const [data, setData] = useState();
    const { dialogProps, showDialog, hideDialog } = useDialog();
    const { fetchData, data: detailEmbeddedUrl, isLoading } = useDataFetcher(getRequestTicketDetailEmbeddedUrl);

    const handleShowDialog = useCallback(
        (data) => {
            setData(data);
            showDialog();
        },
        [showDialog]
    );

    useImperativeHandle(ref, () => ({
        showDialog: handleShowDialog,
        hideDialog,
    }));

    useEffect(() => {
        if (data?.id) {
            fetchData(data.id);
        }
    }, [data?.id, fetchData]);

    return (
        <Dialog
            title="Ticket Details"
            {...dialogProps}
            PaperProps={{
                style: {
                    width: '95vw',
                    maxWidth: '1200px',
                    minHeight: '80vh',
                },
            }}
            sxDialog={{
                '& [class*=MuiPaper-root]': {
                    maxWidth: 'none',
                },
                '& [class*=MuiDialogContent-root]': {
                    padding: 0,
                    display: 'flex',
                    flexDirection: 'row',
                    overflow: 'auto',
                },
            }}
        >
            <Stack width="100%">
                <LoadPanel visible={isLoading} />
                {!!detailEmbeddedUrl && <IframeStyled src={detailEmbeddedUrl} />}
            </Stack>
        </Dialog>
    );
});
RequestTicketDetailDialog.displayName = 'RequestTicketDetailDialog';

export default RequestTicketDetailDialog;
