import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import ChevronRight from '@mui/icons-material/ChevronRight';

import styles from './SidePanel.module.css';

function SidePanel({ children }) {
    const sidePanelRef = useRef(null);
    const minWidth = 20;

    const [isPanelOpen, setIsPanelOpen] = useState(false);
    const [isHovering, setIsHovering] = useState(false);
    const [panelWidth, setPanelWidth] = useState(`${minWidth}px`);

    const updatePanelWidth = (isOpen) => {
        setPanelWidth(`${isOpen ? minWidth : sidePanelRef.current.getBoundingClientRect().width}px`);
    };

    const toggleSidebar = (isOpen) => {
        updatePanelWidth(isOpen);
        setIsPanelOpen(!isOpen);
        setIsHovering(false);
    };

    const hoverSidePanel = (isHoverOn) => {
        if (isHoverOn === isHovering) return;
        setIsHovering(isHoverOn);
        if (!isPanelOpen) {
            updatePanelWidth(!isHoverOn);
        }
    };

    const enterSidePanel = () => {
        hoverSidePanel(true);
    };

    const leaveSidePanel = () => {
        hoverSidePanel(false);
    };

    return (
        <Box className={styles['side-panel-container']} onMouseLeave={leaveSidePanel}>
            <Box className={styles['space-placeholder']} sx={{ width: isPanelOpen ? panelWidth : minWidth }} />
            <Box className={styles['toggle-container']} sx={{ left: panelWidth }} onMouseEnter={isHovering ? enterSidePanel : () => {}}>
                {(!isPanelOpen || isHovering) && (
                    <button
                        className={styles['toggle-button']}
                        tabIndex={0}
                        onClick={() => toggleSidebar(isPanelOpen)}
                        title={isPanelOpen ? 'Collapse' : 'Expand'}
                    >
                        <ChevronRight className={`${styles['arrow']} ${isPanelOpen ? styles['arrow-collapse'] : ''}`} />
                    </button>
                )}
            </Box>
            <Box className={styles['panel']} style={{ width: panelWidth }} onMouseEnter={enterSidePanel}>
                <Box className={`${styles['content']} ${!isPanelOpen && !isHovering ? styles['content--hidden'] : ''}`} ref={sidePanelRef}>
                    {children}
                </Box>
            </Box>
        </Box>
    );
}

SidePanel.propTypes = {
    children: PropTypes.node.isRequired,
};

export default SidePanel;
