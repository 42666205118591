import { Stack, TextField, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { forwardRef, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { authActions } from 'store/auth/authSlice';
import CustomButton from '../../../components/UI/buttons/CustomButton.style';
import { updateUserEmail } from '../../../services/authService';
import { EMAIL_REGEX_FORMAT } from '../../../utils/constants';
import { showNotification } from '../../../utils/notificationsHandler';

const UpdateEmailDialog = forwardRef((props, ref) => {
    const dispatch = useDispatch();
    const { selectedUser, hideDialog } = props;
    const [emailError, setEmailError] = useState('');
    const [emailInputValue, setEmailInputValue] = useState('');

    const handleChangeEmailValue = (event) => {
        setEmailInputValue(event.target.value);
        setEmailError('');
    };

    const handleUpdateUserEmail = useCallback(async () => {
        try {
            const updateEmailPayload = {
                email: emailInputValue,
            };

            await updateUserEmail(updateEmailPayload);
            showNotification({
                title: 'Successfully update email',
                variant: 'success',
            });
            dispatch(authActions.setUser({ ...selectedUser, email: emailInputValue }));
        } catch (error) {
            const errorMessage = error.response?.data?.error || error?.message;
            showNotification({
                title: errorMessage,
                variant: 'error',
            });
        }
    }, [dispatch, emailInputValue, selectedUser]);

    const handleUpdate = useCallback(async () => {
        const isValidEmail = EMAIL_REGEX_FORMAT.test(emailInputValue);
        if (emailInputValue && !isValidEmail) {
            setEmailError('Invalid email format');
            return;
        }
        setEmailError('');
        await handleUpdateUserEmail();
    }, [emailInputValue, handleUpdateUserEmail]);

    return (
        <Stack width="100%" padding={3} pt={5}>
            <Stack spacing={2}>
                <Typography>Please provide your email in order to proceed the adding request</Typography>
                <TextField
                    label="Email"
                    variant="outlined"
                    size="small"
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    value={emailInputValue}
                    onChange={handleChangeEmailValue}
                    error={!!emailError}
                    helperText={emailError}
                />
            </Stack>
            <Stack gap={2} direction="row" justifyContent="flex-end" marginTop="auto">
                <CustomButton customVariant="error" onClick={() => hideDialog()}>
                    Cancel
                </CustomButton>
                <CustomButton customVariant="success" onClick={handleUpdate} disabled={!emailInputValue}>
                    Continue
                </CustomButton>
            </Stack>
        </Stack>
    );
});
UpdateEmailDialog.displayName = 'UpdateEmailDialog';
UpdateEmailDialog.propTypes = {
    selectedUser: PropTypes.object,
    hideDialog: PropTypes.func,
};

export default UpdateEmailDialog;
