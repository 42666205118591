import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import KeyIcon from '@mui/icons-material/Key';
import ListAltIcon from '@mui/icons-material/ListAlt';
import ShieldIcon from '@mui/icons-material/Shield';
import AppointmentIcon from 'components/icons/AppointmentIcon';
import PersonnelIcon from 'components/icons/PersonnelIcon';
import SSOIcon from 'components/icons/SSOIcon';
import Tollbooth from 'components/icons/Tollbooth';
import { ROLES, ROUTE_PATH } from 'utils/constants';

export const getAvailableRoutes = (featureFlags) => {
    const routes = [];
    routes.push({
        allowedRoles: [ROLES.ADMIN, ROLES.HR, ROLES.AGENT, ROLES.HQ_SUPPORT, ROLES.USER, ROLES.TECH, ROLES.SALES],
        path: '/',
        text: 'Homepage',
    });
    routes.push({
        allowedRoles: [ROLES.ADMIN, ROLES.FACILITY_MANAGER, ROLES.ORGANIZATION_ADMIN],
        path: '/access-point-record-list',
        text: 'Access Point Records',
        Icon: Tollbooth,
    });
    if (featureFlags.isAppointmentEnabled) {
        routes.push({
            allowedRoles: [ROLES.ADMIN, ROLES.AGENT, ROLES.FACILITY_MANAGER, ROLES.ORGANIZATION_ADMIN],
            path: '/appointment-list',
            text: 'Appointments',
            Icon: AppointmentIcon,
        });
    }
    routes.push({
        allowedRoles: [ROLES.ADMIN, ROLES.HR, ROLES.USER],
        path: '/employees',
        text: 'Employees',
    });
    routes.push({
        text: 'Authorized Lists',
        Icon: ShieldIcon,
        allowedRoles: [ROLES.ADMIN, ROLES.AGENT, ROLES.FACILITY_MANAGER, ROLES.ORGANIZATION_ADMIN],
        items: [
            {
                Icon: HowToRegIcon,
                path: '/people-authorized-list',
                text: 'People',
            },
            {
                Icon: ListAltIcon,
                path: '/company-authorized-list',
                text: 'Organizations',
            },
        ],
    });
    if (featureFlags.ssoEnabled) {
        routes.push({
            allowedRoles: [ROLES.ORGANIZATION_ADMIN],
            path: ROUTE_PATH.SSO_CONFIGURATION,
            text: 'SSO Configuration',
            Icon: SSOIcon,
        });
    }
    routes.push({
        allowedRoles: [ROLES.ADMIN],
        Icon: KeyIcon,
        path: '/authorized-organization-locations',
        text: 'Authorized Organizations & Locations',
    });
    routes.push({
        allowedRoles: [ROLES.ADMIN, ROLES.USER, ROLES.TECH],
        path: '/monitoring-elements',
        text: 'Monitoring Elements',
    });
    routes.push({
        allowedRoles: [ROLES.ADMIN, ROLES.USER, ROLES.HQ_SUPPORT, ROLES.SALES],
        path: '/organization-list',
        text: 'Organizations',
    });

    if (featureFlags.isClientPersonnelEnabled) {
        routes.push({
            allowedRoles: [ROLES.ADMIN],
            path: '/client-personnel',
            text: 'Client Personnel',
            Icon: PersonnelIcon,
        });
    }
    routes.push({
        allowedRoles: [ROLES.ADMIN, ROLES.USER, ROLES.HQ_SUPPORT, ROLES.SALES],
        path: '/location-list',
        text: 'Locations',
    });
    routes.push({
        allowedRoles: [ROLES.ADMIN, ROLES.USER, ROLES.HQ_SUPPORT, ROLES.SALES],
        path: '/divison-list',
        text: 'Divisions',
    });
    routes.push({
        allowedRoles: [ROLES.ADMIN, ROLES.USER, ROLES.TECH],
        path: '/camera-tron',
        text: 'CameraTron',
    });
    routes.push({
        allowedRoles: [ROLES.ADMIN, ROLES.USER, ROLES.TECH],
        path: '/control-center',
        text: 'Control Center',
    });
    routes.push({
        allowedRoles: [ROLES.ADMIN, ROLES.USER, ROLES.TECH],
        path: '/monitoring-object',
        text: 'Monitoring Object',
    });
    routes.push({
        text: 'Alisa',
        items: [{ path: '/regions-of-interest', text: 'Regions Of Interest' }],
    });

    if (featureFlags.isClientRequestEnabled) {
        routes.push({
            allowedRoles: [ROLES.ADMIN, ROLES.FACILITY_MANAGER, ROLES.ORGANIZATION_ADMIN],
            path: '/request-tickets',
            text: 'Request',
            Icon: ConfirmationNumberIcon,
        });
    }

    return routes;
};
