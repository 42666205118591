import { styled, IconButton } from '@mui/material';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';

const CustomIconButton = styled(IconButton)((props) => {
    const color = props.color ?? 'green';
    return {
        backgroundColor: `var(--color-${color})`,
        width: '2rem',
        height: '2rem',

        '&:hover': {
            backgroundColor: `rgb(from var(--color-${color}) r g b / 80%)`,
        },
    };
});

const CustomAddOutlinedIcon = styled(AddOutlinedIcon)({
    color: 'white',
});

export { CustomIconButton, CustomAddOutlinedIcon };
