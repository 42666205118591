import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PhoneCallbackIcon from '@mui/icons-material/PhoneCallback';
import LanguageIcon from '@mui/icons-material/Language';
import PropTypes from 'prop-types';
import { AvatarStyled } from 'features/RequestTickets/Components/RequestTicketItemChannelIconStyled.js';
import { getAvatarLetters } from 'features/RequestTickets/utils/requestTicketUtils.js';
import { Avatar } from '@mui/material';
import React from 'react';

const getChannelIcon = (channel) => {
    switch (channel) {
        case 'Email':
            return <MailOutlineIcon />;
        case 'Phone':
            return <PhoneCallbackIcon />;
        case 'Web Form':
        case 'Web':
            return <LanguageIcon />;
        default:
            return getAvatarLetters(channel);
    }
};

export const RequestTicketItemChannelIcon = (props) => {
    const { channel } = props;
    const icon = getChannelIcon(channel);
    return <AvatarStyled>{icon}</AvatarStyled>;
};
RequestTicketItemChannelIcon.propTypes = {
    channel: PropTypes.string,
};

export default RequestTicketItemChannelIcon;
