import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import {
    ContainerStack,
    HeaderBreadcrumbsStack,
    CustomTypography,
    CustomBreadcrumbs,
    CustomBreadcrumbsTypography,
    CustomNavigateNextIcon,
} from './BreadcrumbsBar.styled';

BreadcrumbsBar.propTypes = {
    icon: PropTypes.element.isRequired,
    pageName: PropTypes.string.isRequired,
    breadcrumbs: PropTypes.arrayOf(PropTypes.string),
    children: PropTypes.node,
};

function BreadcrumbsBar({ icon, pageName, breadcrumbs = [], children }) {
    return (
        <ContainerStack direction="row" spacing={1}>
            {icon}
            <HeaderBreadcrumbsStack direction="row" spacing={1}>
                <CustomTypography variant="h6">{pageName}</CustomTypography>
                {breadcrumbs.length > 0 && <CustomNavigateNextIcon fontSize="small" />}
                <CustomBreadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumbs">
                    {breadcrumbs.map((label, index) => (
                        <CustomBreadcrumbsTypography key={`${label}-${index}`} variant={index === breadcrumbs.length - 1 ? 'h6' : 'body1'}>
                            {label}
                        </CustomBreadcrumbsTypography>
                    ))}
                </CustomBreadcrumbs>
            </HeaderBreadcrumbsStack>
            <Stack direction="row" spacing={2}>
                {children}
            </Stack>
        </ContainerStack>
    );
}

export default BreadcrumbsBar;
