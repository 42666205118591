import { styled } from '@mui/system';
import Box from '@mui/material/Box';

const ContainerStyled = styled(Box)({
    display: 'grid',
    gridTemplateAreas: `'SidePanel Content'`,
    gridTemplateColumns: 'max-content 1fr',
    width: 'auto',
    height: 'calc(100vh - var(--header__height--large) - var(--breadcrumbs-bar__height))',
    marginTop: '-8px',
    overscrollBehavior: 'none',
});

const ContentStyled = styled(Box)({
    gridArea: 'Content',
    overflow: 'auto',
    boxSizing: 'border-box',
});

export { ContainerStyled, ContentStyled };
