import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import BreadcrumbsBar from 'components/UI/BreadcrumbsBar/BreadcrumbsBar.jsx';
import AddIconButton from 'components/UI/buttons/AddIconButton.jsx';
import RefreshIcon from '@mui/icons-material/Refresh';
import Dialog from 'components/UI/dialogs/Dialog.jsx';
import { useDialog } from 'components/UI/dialogs/hooks/useDialog.js';
import AddRequestTicketDialog from 'features/RequestTickets/Components/AddRequestTicketDialog.jsx';
import React, { useCallback } from 'react';
import useAuthUser from '../../../hooks/useAuthUser';
import UpdateEmailDialog from './UpdateEmailDialog';
import { CustomIconButton } from 'components/UI/buttons/AddIconButton.styles.js';
import PropTypes from 'prop-types';
import { useSnackbarNotification } from 'utils/notificationsHandler.js';

function RequestTicketsBreadcrumbsBar(props) {
    const { requestTicketTableRef } = props;
    const { user: selectedUser } = useAuthUser();
    const { dialogProps, showDialog, hideDialog } = useDialog();
    const showNotification = useSnackbarNotification();

    const isEmailNeeded = !selectedUser?.email;
    const handleCloseDialog = useCallback(() => {
        hideDialog();
        if (isEmailNeeded) {
            return;
        }
        requestTicketTableRef?.current?.reloadData();
        showNotification(
            true,
            'Processing your request may take up to 2 minutes before it appears in the ticket list. Please click the refresh button to update the list.',
            'success'
        );
    }, [hideDialog, isEmailNeeded, requestTicketTableRef, showNotification]);

    const handleRefreshButtonClick = useCallback(() => {
        requestTicketTableRef?.current?.reloadData();
    }, [requestTicketTableRef]);

    return (
        <BreadcrumbsBar icon={<ConfirmationNumberIcon />} pageName="Request">
            <CustomIconButton color="white" onClick={handleRefreshButtonClick}>
                <RefreshIcon />
            </CustomIconButton>
            <AddIconButton onClick={showDialog} />
            <Dialog
                {...dialogProps}
                onClose={handleCloseDialog}
                title="Add a New Request"
                PaperProps={{
                    style: {
                        width: '95vw',
                        maxWidth: '50rem',
                        minHeight: '80vh',
                    },
                }}
                sxDialog={{
                    '& [class*=MuiPaper-root]': {
                        maxWidth: 'none',
                    },
                    '& [class*=MuiDialogContent-root]': {
                        padding: 0,
                        display: 'flex',
                        flexDirection: 'row',
                        overflow: 'auto',
                    },
                }}
            >
                {!isEmailNeeded ? <AddRequestTicketDialog /> : <UpdateEmailDialog hideDialog={hideDialog} selectedUser={selectedUser} />}
            </Dialog>
        </BreadcrumbsBar>
    );
}
RequestTicketsBreadcrumbsBar.propTypes = {
    requestTicketTableRef: PropTypes.object,
};

export default RequestTicketsBreadcrumbsBar;
