import { Stack, styled } from '@mui/material';

const StickyStack = styled(Stack)({
    position: 'fixed',
    width: '100%',
    top: 'var(--header__height--large)',
    zIndex: 100,
    backgroundColor: 'white',
    borderBottom: '1px solid #e0e0e0',
    padding: '0 1rem',
});

const ContentStack = styled(Stack)({
    marginTop: 'var(--breadcrumbs-bar__height)',
});

export { ContentStack, StickyStack };
