import { Alert, Pagination } from '@mui/material';
import { LoadPanel } from 'devextreme-react/load-panel';
import RequestTicketList from 'features/RequestTickets/Components/RequestTicketList.jsx';
import Stack from '@mui/material/Stack';
import { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react';
import useGlobalLocation from 'components/globalLocationSelector/hooks/useGlobalLocation';
import { DATA_TABLE_DEFAULT_PAGE_SIZE } from 'utils/constants.js';
import { useDataFetcher } from 'hooks/useDataFetcher.js';
import { getMyRequestTicketList } from 'services/requestTicketService.js';
import useAuthUser from 'hooks/useAuthUser.js';
import PropTypes from 'prop-types';

const RequestTicketTable = forwardRef((props, ref) => {
    const { searchText, checkedStatus } = props;
    const [page, setPage] = useState(0);
    const { selectedLocationId } = useGlobalLocation();
    const { user } = useAuthUser();

    const pageSize = DATA_TABLE_DEFAULT_PAGE_SIZE;
    const { fetchData, data, setData, isLoading, isFetching } = useDataFetcher(getMyRequestTicketList);

    const hasAnyCheckedStatus = Object.keys(checkedStatus).some((key) => checkedStatus[key]);

    const reloadData = useCallback(async () => {
        if (!selectedLocationId || !user) {
            return;
        }
        if (!hasAnyCheckedStatus) {
            setData(null);
            return;
        }
        if (searchText && searchText.length < 3) {
            return;
        }
        const params = {
            locationId: selectedLocationId,
            size: pageSize,
            page,
        };
        if (searchText) {
            params.all = searchText;
        }

        const status = Object.keys(checkedStatus)
            .filter((key) => checkedStatus[key])
            .join(',');
        if (status) {
            params.status = status;
        }

        return fetchData(params);
    }, [checkedStatus, fetchData, hasAnyCheckedStatus, page, pageSize, searchText, selectedLocationId, setData, user]);

    useEffect(() => {
        reloadData();
    }, [reloadData]);

    useImperativeHandle(ref, () => ({
        reloadData,
    }));

    const handleChange = useCallback((_, value) => {
        setPage(value);
    }, []);

    const tickets = data?.data ?? [];
    const totalCount = data?.count ?? 0;
    const totalPage = Math.ceil(totalCount / pageSize);

    return (
        <>
            <Stack p={2} gap={2}>
                {tickets?.length ? (
                    <>
                        <RequestTicketList requestList={tickets} />
                        <Pagination count={totalPage} onChange={handleChange} sx={{ margin: 'auto' }} />
                    </>
                ) : (
                    !isLoading && <Alert severity="info">No request tickets found.</Alert>
                )}
            </Stack>
            <LoadPanel visible={isFetching} />
        </>
    );
});

RequestTicketTable.displayName = 'RequestTicketTable';
RequestTicketTable.propTypes = {
    searchText: PropTypes.string,
    checkedStatus: PropTypes.object,
};

export default RequestTicketTable;
