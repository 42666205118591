import { Stack, Typography } from '@mui/material';
import AIQInput from 'components/UI/AIQInput/AIQInput';
import SidePanel from 'components/UI/SidePanel/SidePanel';
import { useMemo, useState } from 'react';
import { useDebounce } from 'hooks/useDebounce.js';
import { REQUEST_TICKET_STATUS } from 'features/RequestTickets/utils/requestTicketConstants.js';
import Switch from 'components/UI/inputs/Switch.jsx';
import PropTypes from 'prop-types';

function RequestTicketFilterBar(props) {
    const { onSearchChange, onFilterChange, defaultCheckedStatus } = props;
    const [checkedStatus, setCheckedStatus] = useState(defaultCheckedStatus);
    const handleSearchTextChange = useDebounce((e) => {
        onSearchChange(e.target.value);
    });
    const handleFilterChange = useDebounce((checkedValue) => {
        onFilterChange(checkedValue);
    });

    const renderedFilterItems = useMemo(() => {
        return Object.values(REQUEST_TICKET_STATUS).map((status) => (
            <Stack key={status} gap={1} direction="row" justifyContent="space-between">
                <Typography color="gray3.main">{status}</Typography>
                <Switch
                    key={status}
                    label={status}
                    checked={checkedStatus[status]}
                    onChange={() => {
                        setCheckedStatus((prev) => {
                            const nextCheckedStatus = { ...prev, [status]: !prev[status] };
                            handleFilterChange(nextCheckedStatus);
                            return nextCheckedStatus;
                        });
                    }}
                />
            </Stack>
        ));
    }, [checkedStatus, handleFilterChange]);

    return (
        <SidePanel>
            <Stack
                gap={2}
                sx={{
                    width: 'calc(100% - 0.5rem)',
                }}
            >
                <AIQInput onChange={handleSearchTextChange} inputProps={{ placeholder: 'Search...' }} />
                <Typography fontWeight={700} color="gray3.main">
                    FILTER
                </Typography>
                <Stack gap={1}>{renderedFilterItems}</Stack>
            </Stack>
        </SidePanel>
    );
}

RequestTicketFilterBar.propTypes = {
    onSearchChange: PropTypes.func,
    onFilterChange: PropTypes.func,
    defaultCheckedStatus: PropTypes.object,
};

export default RequestTicketFilterBar;
