import { Stack } from '@mui/material';
import useGlobalLocation from 'components/globalLocationSelector/hooks/useGlobalLocation.js';
import { LoadPanel } from 'devextreme-react/load-panel.js';
import { IframeStyled } from 'features/RequestTickets/Components/RequestTicketDetailDialogStyled.js';
import { useDataFetcher } from 'hooks/useDataFetcher.js';
import { forwardRef, useEffect } from 'react';
import { getRequestFormUrl } from 'services/requestTicketService.js';

const AddRequestTicketDialog = forwardRef(() => {
    const { selectedLocationId } = useGlobalLocation();
    const { fetchData, data: formUrl, isLoading } = useDataFetcher(getRequestFormUrl);

    useEffect(() => {
        if (selectedLocationId) {
            fetchData(selectedLocationId);
        }
    }, [selectedLocationId, fetchData]);

    return (
        <Stack width="100%">
            <LoadPanel visible={isLoading} />
            {!!formUrl && <IframeStyled src={formUrl} />}
        </Stack>
    );
});
AddRequestTicketDialog.displayName = 'AddRequestTicketDialog';

export default AddRequestTicketDialog;
