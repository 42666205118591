function createNameArray(namePath) {
    const nameArr = namePath.split(/[[\].]{1,2}/).reduce((acc, item) => {
        if (item === '') return acc;
        acc.push(item);
        return acc;
    }, []);
    const maxIndex = nameArr.length - 1;
    return [nameArr, maxIndex];
}

export const readFromObj = function (stateObj, namePath, del = false) {
    const [nameArr, maxIndex] = createNameArray(namePath);
    let property = stateObj;
    if (property)
        nameArr.forEach((item, i) => {
            if (!property || property === 'lasta.fieldNotExist') return;
            if (item in property) {
                if (i === maxIndex && del) {
                    delete property[item];
                }
                property = property[item];
            } else {
                property = 'lasta.fieldNotExist';
            }
        });

    return property;
};

export const writeInObj = function (stateObj, namePath, value, options = {}) {
    if (!stateObj) return;
    const { action, index } = options;
    const [nameArr, maxIndex] = createNameArray(namePath);

    let property = stateObj;

    nameArr.forEach((item, i, arr) => {
        if (value) {
            property[item] = value;
        }
        if (i === maxIndex) {
            if (property === null) return;
            if (
                // (!value && value !== '' && !property[item]) ||
                property?.[item] === value ||
                !(item in property)
            )
                return;

            switch (action) {
                case undefined:
                    property[item] = value;

                    break;

                case 'push':
                    property[item].push(value);
                    break;

                case 'remove':
                    property[item].splice(index, 1);
                    break;

                default:
            }
            return;
        }

        if (item in property) {
            property = property[item];
        } else {
            // property[item] = namePath.includes(`[${arr[i + 1]}]`) ? [] : {};
            // property = property[item];
            return;
        }
    });
};

export function getCookie(cname) {
    let name = cname + '=';
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return '';
}

export const getEmailDomain = (email = '') => {
    try {
        const domain = email.split('@')?.[1]?.toLowerCase();
        return domain || null;
    } catch {
        return null;
    }
};

export const isSameEmailDomain = (email_1 = '', email_2 = '') => {
    return getEmailDomain(email_1) === getEmailDomain(email_2);
};

export const deepSearch = (obj, searchText) => {
    if (typeof obj === 'string') {
        return obj.toLowerCase().includes(searchText.toLowerCase());
    }
    if (typeof obj === 'number') {
        return obj.toString().includes(searchText);
    }
    if (typeof obj === 'object' && obj !== null) {
        return Object.values(obj).some((value) => deepSearch(value, searchText));
    }
    return false;
};
