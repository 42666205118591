import { useState, useCallback } from 'react';

export const useDialog = () => {
    const [open, setOpen] = useState(false);

    const showDialog = useCallback(() => {
        setOpen(true);
    }, []);
    const hideDialog = useCallback(() => {
        setOpen(false);
    }, []);

    const handleClose = useCallback(() => {
        hideDialog();
    }, [hideDialog]);

    const dialogProps = {
        open,
        onClose: handleClose,
    };
    return {
        showDialog,
        hideDialog,
        dialogProps,
    };
};
