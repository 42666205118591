import { toQueryString } from 'utils/queryStringHelpers.js';
import { axiosLasta } from 'config';
import { DATA_TABLE_DEFAULT_PAGE_SIZE } from 'utils/constants.js';

export const getRequestTicketList = async (opts) => {
    const { locationId, ...otherOpts } = opts;
    const query = toQueryString({
        size: DATA_TABLE_DEFAULT_PAGE_SIZE,
        page: 0,
        ...otherOpts,
    });
    const url = `/client-requests/location/${locationId}`;
    const response = await axiosLasta(`${url}?${query}`);
    return response?.data;
};

export const getMyRequestTicketList = async (opts) => {
    const { locationId, ...otherOpts } = opts;
    const query = toQueryString({
        size: DATA_TABLE_DEFAULT_PAGE_SIZE,
        page: 0,
        ...otherOpts,
    });
    const url = `/client-requests/my-requests/location/${locationId}`;
    const response = await axiosLasta(`${url}?${query}`);
    return response?.data;
};

export const getRequestTicketDetailEmbeddedUrl = async (id) => {
    const response = await axiosLasta(`/client-requests/${id}/embedded-url`);
    return response?.data;
};

export const getRequestFormUrl = async (locationId) => {
    const response = await axiosLasta(`/client-requests/request-form-url/location/${locationId}`);
    return response?.data;
};
