import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';

import { StickyStack, ContentStack } from './PageWithBreadcrumbs.styled';

PageWithBreadcrumbs.propTypes = {
    breadcrumbsBar: PropTypes.node.isRequired,
    children: PropTypes.node.isRequired,
};

function PageWithBreadcrumbs({ breadcrumbsBar, children }) {
    return (
        <Stack>
            <StickyStack>{breadcrumbsBar}</StickyStack>
            <ContentStack>{children}</ContentStack>
        </Stack>
    );
}

export default PageWithBreadcrumbs;
