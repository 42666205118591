import { Avatar, Badge, IconButton, Stack, Typography } from '@mui/material';
import React from 'react';
import { formatDateTime, fromIsoString } from 'utils/dateTimeHelpers';

import MessageIcon from '@mui/icons-material/Message';
import NotesIcon from '@mui/icons-material/Notes';
import PropTypes from 'prop-types';
import ScheduleIcon from '@mui/icons-material/Schedule';
import { ItemStatusStyled } from 'features/RequestTickets/Components/RequestTicketItemStyled.js';
import RequestTicketItemChannelIcon from 'features/RequestTickets/Components/RequestTicketItemChannelIcon.jsx';
import { getAvatarLetters } from 'features/RequestTickets/utils/requestTicketUtils.js';

const RequestTicketItem = (props) => {
    const { ticketItem = {}, onMessageButtonClick } = props;
    const { createdTime, ticketNumber, subject, contact, threadCount, commentCount, status } = ticketItem;

    const fullName = `${contact?.firstName ?? ''} ${contact?.lastName ?? ''}`;
    return (
        <Stack flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} width="100%" p={3}>
            <Stack flexDirection={'row'} gap={2} alignItems={'center'}>
                <RequestTicketItemChannelIcon channel={ticketItem?.channel} />
                <Stack gap={1} flexDirection={'column'}>
                    <Typography fontWeight={'bold'}>{subject}</Typography>
                    <Stack alignItems="center" flexDirection={'row'} gap={2}>
                        <Typography variant="subtitle1">#{ticketNumber}</Typography>
                        <Typography variant="subtitle1">{fullName}</Typography>
                        <Stack alignItems="center" flexDirection={'row'} gap={0.5}>
                            <ScheduleIcon fontSize="small" color="action" />
                            <Typography variant="subtitle1">{formatDateTime(fromIsoString(createdTime))}</Typography>
                        </Stack>
                    </Stack>
                </Stack>
            </Stack>

            <Stack flexDirection={'row'} gap={3} alignItems={'center'}>
                <ItemStatusStyled status={status} fontWeight="bold">
                    {status}
                </ItemStatusStyled>
                <IconButton onClick={onMessageButtonClick}>
                    <Badge badgeContent={Number(threadCount)} color="default">
                        <NotesIcon color="action" sx={{ transform: 'rotateX(180deg)' }} />
                    </Badge>
                </IconButton>
                <IconButton onClick={onMessageButtonClick}>
                    <Badge badgeContent={Number(commentCount)} color="default">
                        <MessageIcon color="action" />
                    </Badge>
                </IconButton>
                <Avatar>{getAvatarLetters(fullName)}</Avatar>
            </Stack>
        </Stack>
    );
};

export default RequestTicketItem;
RequestTicketItem.propTypes = {
    ticketItem: PropTypes.object,
    onMessageButtonClick: PropTypes.func,
};
