import isEmpty from 'lodash/isEmpty';
import { useCallback, useState } from 'react';
import { getFormattedErrorMessageFromErrorObject } from 'utils/formatErrorCode';
import { showNotification } from 'utils/notificationsHandler';

export const useDataFetcher = (dataFetchFunctions) => {
    const [data, setData] = useState();
    const [isFetching, setIsFetching] = useState(false);
    const [error, setError] = useState(null);

    const fetchData = useCallback(
        async (...params) => {
            setIsFetching(true);
            try {
                if (Array.isArray(dataFetchFunctions)) {
                    const results = await Promise.all(dataFetchFunctions.map((fetchFunction, index) => fetchFunction(...(params[index] || []))));
                    setData(results);
                } else {
                    const result = await dataFetchFunctions(...params);
                    setData(result);
                }
                setError(null);
            } catch (error) {
                setError(error);
                const formattedError = getFormattedErrorMessageFromErrorObject(error);
                showNotification({ title: formattedError });
            } finally {
                setIsFetching(false);
            }
        },
        [dataFetchFunctions]
    );

    return {
        isLoading: isFetching && isEmpty(data),
        isFetching,
        data,
        error,
        fetchData,
        setData,
    };
};
