import { styled } from '@mui/system';
import { Typography } from '@mui/material';
import { REQUEST_TICKET_STATUS } from 'features/RequestTickets/utils/requestTicketConstants.js';

export const ItemStatusStyled = styled(Typography)(({ theme, status }) => ({
    color:
        status === REQUEST_TICKET_STATUS.CLOSED
            ? theme.palette.success.main
            : status === REQUEST_TICKET_STATUS.ON_HOLD
              ? theme.palette.warning.main
              : status === REQUEST_TICKET_STATUS.ESCALATED
                ? theme.palette.error.main
                : theme.palette.text.primary,
}));
