import { axiosLasta } from 'config';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { FormOrganizationSaga } from 'store/formControl/formOrgnaizationSaga';
import { getFormattedErrorMessageFromErrorObject } from 'utils/formatErrorCode';
import notificationsHandler from 'utils/notificationsHandler';
import { throttleAction } from 'utils/sagaHelpers';
import { organizationListActions } from './organizationListSlice';

export function* fetchMasterList() {
    try {
        const response = yield axiosLasta(`/organization/all`);
        const { data } = response;
        yield put(organizationListActions.fetchMasterListSuccess(data));
    } catch (err) {
        const formattedError = getFormattedErrorMessageFromErrorObject(err);
        yield notificationsHandler({ title: formattedError });
    }
}

export function* onFetchingMasterListStart() {
    yield throttleAction(organizationListActions.fetchMasterListStart.type, fetchMasterList);
}

export function* onSubmitFormStart() {
    yield takeLatest(organizationListActions.submitFormStart, FormOrganizationSaga);
}

export function* onAddItemToMasterList() {
    yield takeLatest(organizationListActions.addItemToMasterList.type, FormOrganizationSaga);
}

export function* organizationListSaga() {
    yield all([call(onSubmitFormStart), call(onAddItemToMasterList), call(onFetchingMasterListStart)]);
}
