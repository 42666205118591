import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import { ContainerStyled, ContentStyled } from 'components/pageWithLeftSideBar/PageWithLeftSideBarStyled.js';

export const PageWithLeftSideBar = (props) => {
    return (
        <ContainerStyled>
            {props.sideBar}
            <ContentStyled>{props.children}</ContentStyled>
        </ContainerStyled>
    );
};

PageWithLeftSideBar.propTypes = {
    sideBar: PropTypes.element,
    children: PropTypes.element,
};

export default PageWithLeftSideBar;
