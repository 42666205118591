import PropTypes from 'prop-types';

import { CustomIconButton, CustomAddOutlinedIcon } from './AddIconButton.styles';

AddIconButton.propTypes = {
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
};

function AddIconButton({ onClick, disabled }) {
    return (
        <CustomIconButton onClick={onClick} disabled={disabled}>
            <CustomAddOutlinedIcon />
        </CustomIconButton>
    );
}

export default AddIconButton;
