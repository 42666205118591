import { useSelector } from 'react-redux';
import { selectUser, selectIsUserDataRequested } from 'store/auth/authSelectors';
import { useCallback } from 'react';

const useAuthUser = () => {
    const user = useSelector(selectUser);
    const isUserDataRequested = useSelector(selectIsUserDataRequested);

    const userHasRoles = useCallback(
        (roles) => {
            if (!user) {
                return false;
            }
            return user?.roles?.some((role) => roles.includes(role));
        },
        [user]
    );

    return {
        user,
        userHasRoles,
        isUserDataRequested,
    };
};

export default useAuthUser;
