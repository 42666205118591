import { Stack, styled, Typography, Breadcrumbs } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

const ContainerStack = styled(Stack)({
    display: 'flex',
    width: '100%',
    height: 'var(--breadcrumbs-bar__height)',
    backgroundColor: 'var(--color-white)',
    alignItems: 'center',
});

const HeaderBreadcrumbsStack = styled(Stack)({
    flex: 1,
    paddingTop: '0.2rem',
    alignItems: 'center',
});

const CustomTypography = styled(Typography)({
    color: '#4f4f4f',
    fontSize: '1.125rem',

    '&.MuiTypography-h6': {
        fontWeight: 700,
    },

    '&.MuiTypography-body1': {
        fontWeight: 400,
    },
});

const CustomBreadcrumbs = styled(Breadcrumbs)({
    flex: 1,
});

const CustomBreadcrumbsTypography = styled(Typography)({
    color: '#595959',
    fontSize: '1rem',
    fontWeight: 400,
});

const CustomNavigateNextIcon = styled(NavigateNextIcon)({
    alignItems: 'center',
    color: 'var(--color-dark-grey)',
});

export { ContainerStack, HeaderBreadcrumbsStack, CustomTypography, CustomBreadcrumbs, CustomBreadcrumbsTypography, CustomNavigateNextIcon };
