import { useMemo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as formControlSelectors from 'store/formControl/formControlSelectors';
import { formControlActions } from 'store/formControl/formControlSlice';
import { getFormFieldConfig } from 'utils/formFieldHelper';
import * as monitoringObjectSelector from 'store/monitoringObject/monitoringObjectSelectors';
import styles from './Form.module.css';
import InputField from 'components/UI/inputs/InputField';
import Select from 'components/UI/select/Select';

// TODO: Add component prop-types. Disabling the eslint rule for now.
/* eslint-disable react/prop-types */
function Form({ formId, fields, classes, children, data, formStatus, dropdownValues, dropdownPersonValues }) {
    const dispatch = useDispatch();
    const selectFormByIdSelector = useMemo(() => formControlSelectors.selectFormById(formId), [formId]);
    const isEditing = useSelector(monitoringObjectSelector.selectIsEditing);
    const isOptionEqualToValueFn = (option, value) => option.id === value.id;

    useEffect(() => {
        dispatch(
            formControlActions.setFormValues({
                formId,
                newFormValues: data,
            })
        );
    }, [data, dispatch, formId]);

    const form = useSelector(selectFormByIdSelector);
    const { values, errors, touched } = form?.form || {};

    const formFields = fields.map((fieldConfig) => {
        const { fieldName } = fieldConfig;

        const key = fieldName;
        const options = {
            value: values?.[fieldName] || '',
            error: errors?.[fieldName],
            touched: touched?.[fieldName],
            ...getFormFieldConfig({
                fieldConfig,
                formId,
                disabled: formStatus === 'viewOnly',
            }),
        };
        if (fieldConfig && fieldConfig?.lookup?.displayExpr === 'yardName') {
            const lookupObj = {
                ...fieldConfig?.lookup,
                dataSource: dropdownValues,
            };

            return (
                <Select
                    key={key}
                    containerClasses={styles['input-select']}
                    {...options}
                    lookup={lookupObj}
                    isOptionEqualToValueFn={isOptionEqualToValueFn}
                    {...(isEditing ? { disabled: true } : { disabled: false })}
                />
            );
        }

        if (fieldConfig && fieldConfig?.lookup?.displayExpr === 'objectType') {
            const lookupObj = {
                ...fieldConfig?.lookup,
                dataSource: dropdownPersonValues,
            };

            return (
                <Select
                    key={key}
                    {...options}
                    lookup={lookupObj}
                    containerClasses={styles['input-select']}
                    isOptionEqualToValueFn={isOptionEqualToValueFn}
                />
            );
        } else {
            return <InputField key={key} classes={styles['input']} {...options} />;
        }
    });
    return (
        <form id={formId} className={classes}>
            {formFields}
            {children}
        </form>
    );
}

export default Form;
